import React from "react";
import styled from "styled-components";

import spinner from "./images/app-loading-spinner.svg";

const Styled = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  flex-direction: column;
  gap: 1rem;
  color: #999;

  svg {
    fill: blue;
    width: 4.8rem;
  }
`;

/** This will play a fancy animation as the application loads. */
const AppLoading: React.FC = (props) => {
  return (
    <Styled>
      <img alt="Loading" src={spinner} width="48" />
      <div>Loading Student Portal</div>
    </Styled>
  );
};

export default AppLoading;
